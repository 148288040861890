@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: local('Prompt'), local('Prompt-Regular'),
    url('../fonts/prompt-v4-latin-ext_latin-regular.woff2') format('woff2'),
    url('../fonts/prompt-v4-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  src: local('Prompt Medium'), local('Prompt-Medium'),
    url('../fonts/prompt-v4-latin-ext_latin-500.woff2') format('woff2'),
    url('../fonts/prompt-v4-latin-ext_latin-500.woff') format('woff');
}

@layer components {
  .settings-menu {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
      rgba(8, 22, 43, 0.1);
  }

  .settings-menu-active {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
      rgba(8, 22, 43, 0.3);
  }

  .private-menu .active {
    @apply bg-white font-bold;
  }

  .private-sidebar .active {
    @apply font-semibold;
  }

  .form-item {
    @apply sm:flex sm:items-center;
  }

  .form-item label {
    @apply ml-2 text-sm text-black sm:w-1/3 sm:ml-0 sm:text-right sm:mr-4;
  }

  .form-item.form-item-readonly label {
    @apply text-gray-light;
  }

  .app-table {
    @apply border-gray-lightest border-t-2 max-w-xs block overflow-x-scroll whitespace-nowrap sm:w-full sm:max-w-full sm:table sm:overflow-x-auto;
  }

  .app-table thead {
    @apply border-gray-lightest border-b;
  }

  .app-table th {
    @apply p-2 text-left font-normal text-xl;
  }

  .app-table td {
    @apply p-2;
  }

  .app-table tr {
    @apply hover:bg-gray-lightest;
  }
}

:root {
  --content-min-height: calc(100vh - 382px);
}

/* Paystra card brand select */
.wpwl-form .wpwl-label.wpwl-label-brand,
.wpwl-form .wpwl-wrapper.wpwl-wrapper-brand {
  display: none;
}

.wpwl-control.wpwl-control-cardHolder {
  @apply focus:outline-none;

  padding-left: 8px;
  border: none;
  border-radius: 3px;
}

.wpwl-form input::placeholder {
  color: rgb(117, 117, 117);
}

/* Old generated css from here. */

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
  min-height: var(--content-min-height);
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: rgba(0, 0, 0, 0);
}
b,
strong {
  font-weight: bolder;
}
code {
  font-family: monospace, monospace;
  font-size: 1em;
}
img {
  border-style: none;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
details {
  display: block;
}
[hidden],
template {
  display: none;
}
h1,
h2,
h3,
h5,
p,
pre {
  margin: 0;
}
button {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
fieldset,
ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
}
img {
  border-style: solid;
}
textarea {
  resize: vertical;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a0aec0;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #a0aec0;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a0aec0;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #a0aec0;
}
input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}
[role='button'],
button {
  cursor: pointer;
}
table {
  border-collapse: collapse;
}
h1,
h2,
h3,
h5 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
button,
input,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}
code,
pre {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}
canvas,
img,
svg {
  display: block;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

html {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
  font-family: Prompt, sans-serif;
  scroll-behavior: smooth;
}
a {
  text-decoration: underline;
}
a:hover,
h1 {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
h1 {
  font-size: 1.875rem;
  font-weight: 500;
}
h2 {
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1;
}
section {
  scroll-margin-top: 8rem;
}
p {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
input[type='email'],
input[type='url'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='text'],
select,
textarea {
  border-radius: 9999px;
  border-width: 2px;
  --border-opacity: 1;
  border-color: #08162b;
  border-color: rgba(8, 22, 43, var(--border-opacity));
  padding: 0.25rem 1rem;
  width: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
input[type='email']:read-only,
input[type='url']:read-only,
input[type='number']:read-only,
input[type='password']:read-only,
input[type='search']:read-only,
input[type='text']:read-only,
select:disabled {
  border-radius: 9999px;
  border-width: 2px;
  --border-opacity: 1;
  border-color: #aaaaaa;
  color: #aaaaaa;
  padding: 0.25rem 1rem;
  width: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
textarea:read-only {
  border-width: 2px;
  --border-opacity: 1;
  border-color: #aaaaaa;
  color: #aaaaaa;
  padding: 0.25rem 1rem;
  width: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
textarea {
  border-radius: 24px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 25px;
  background: url('../img/dropdown-arrow.svg') no-repeat #ffffff;
  background-position-x: 98%;
  background-position-y: center;
}

.language-select {
  width: 45px;
  background-position-y: 30%;
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
input::-ms-input-placeholder,
select::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
input::placeholder,
select::placeholder,
textarea::placeholder {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
@media (min-width: 640px) {
  h1 {
    font-size: 2.25rem;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
.smart-solution-images {
  width: 100%;
}
.why-us-image-container {
  width: 100%;
  position: absolute;
  display: none;
  margin-left: auto;
  margin-right: auto;
  bottom: -55vw;
  width: 100%;
  max-width: 835px;
}
.usecases li {
  margin: 1rem 0;
}
.smart h3 {
  font-size: 1rem;
  font-weight: 700;
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.smart .-mt-12 > div {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 25%;
}
.smart .circles-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  overflow-x: hidden;
  background-image: url('../img/terminal_circles_mobile.svg');
}
.desktop-swipe-holder {
  display: none;
  width: 40%;
  margin-top: 8rem;
  margin-bottom: 6rem;
  height: 40rem;
}

.mobile-swipe-holder {
  width: 100%;
  height: 40rem;
  margin-top: -190px;
}
@media (min-width: 640px) {
  .smart-solution-images {
    width: 50%;
    margin-left: -3rem;
  }
  .smart .circles-container {
    background-image: url('../img/terminal_circles.svg');
    max-width: 80%;
  }
  .smart .terminal {
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .smart-solution-images {
    margin-left: -6rem;
  }
  .why-us-image-container {
    display: block;
  }
  .mobile-swipe-holder {
    display: none;
  }
  .desktop-swipe-holder {
    display: block;
  }
}
@media (min-width: 1024px) {
  .why-us-image-container {
    bottom: -33rem;
  }
}
.opacity-30 {
  opacity: 0.3;
}
.nav-items a {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.nav-items .btn-login,
.nav-items .btn-secondary,
.nav-text {
  display: none;
}
.nav-text {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.25rem;
  text-decoration: none;
}
@media (min-width: 768px) {
  .nav-items .btn-login,
  .nav-items .btn-secondary {
    display: block;
  }
}
@media (min-width: 1024px) {
  .nav-text {
    display: block;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}
@media (min-width: 1280px) {
  .nav-text {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.nav-text:hover {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.nav-text:active {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity));
}
.nav-text:focus {
  outline: 0;
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}
label.required:before {
  content: '* ';
  color: tomato;
}
.error-message,
.error-messages {
  font-weight: 500;
  color: tomato;
}
.btn-primary {
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #0056ff;
  background-color: rgba(0, 86, 255, var(--bg-opacity));
  white-space: nowrap;
  text-decoration: none;
}
.btn-secondary {
  --bg-opacity: 1;
  background-color: #00e76e;
  background-color: rgba(0, 231, 110, var(--bg-opacity));
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.btn-outlined {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: 1px solid #00e76e;
  color: #00e76e;
}
.btn-outlined:hover {
  background-color: #00e76e;
  color: #fff;
}
.btn-error {
  --bg-opacity: 1;
  background-color: #e53e3e;
  color: #fff;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  --text-opacity: 1;
  border-width: 1px;
  --border-opacity: 1;
  border-color: #e53e3e;
  white-space: nowrap;
  text-decoration: none;
}
.btn-login,
.btn-secondary {
  border-radius: 25px;
  padding: 0.5rem 1rem;
  --text-opacity: 1;
  border-width: 1px;
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
  white-space: nowrap;
  text-decoration: none;
}
@media (min-width: 768px) {
  .btn-login,
  .btn-secondary {
    border-radius: 9999px;
  }
}
.btn-login {
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.btn-primary:hover {
  --bg-opacity: 1;
  background-color: #08162b;
  background-color: rgba(8, 22, 43, var(--bg-opacity));
}

.btn-login:hover,
.btn-secondary:hover {
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  border-width: 1px;
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
  --text-opacity: 1;
  white-space: nowrap;
  text-decoration: none;
}
.btn-login:hover {
  --bg-opacity: 1;
  color: #00e76e;
}
.btn-secondary:hover {
  color: #fff;
}
.why-us li {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.why-us strong {
  font-weight: 400;
  color: #bcffdc;
}
.why-us .line-container-left,
.why-us .line-container-right {
  width: 100%;
  margin-top: 1rem;
  height: 2px;
}
@media (min-width: 768px) {
  .why-us .line-container-left,
  .why-us .line-container-right {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.why-us .line-container-left > div {
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#08162b),
    color-stop(#00e76e),
    to(#00e76e)
  );
  background-image: -o-linear-gradient(left, #08162b, #00e76e, #00e76e);
  background-image: linear-gradient(90deg, #08162b, #00e76e, #00e76e);
}
.why-us .line-container-right > div {
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#08162b),
    color-stop(#00e76e),
    to(#00e76e)
  );
  background-image: -o-linear-gradient(right, #08162b, #00e76e, #00e76e);
  background-image: linear-gradient(270deg, #08162b, #00e76e, #00e76e);
}
.pricing strong {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  font-weight: 700;
}
.pricing div {
  -webkit-transition-property: background-color, border-color, color, fill,
    stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, -webkit-box-shadow, -webkit-transform;
  -o-transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.pricing .line-container {
  width: 8.333333%;
}
.pricing .line-container > div {
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
  height: 50%;
}
.pricing .text-container-left {
  padding-left: 3rem;
  text-align: left;
  background-image: url('../img/pricing_circles_left.svg');
}
.pricing .text-container-left,
.pricing .text-container-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.25rem;
  background-repeat: no-repeat;
  min-height: 200px;
}
.pricing .text-container-right {
  padding-right: 3rem;
  text-align: right;
  background-position: 100%;
  background-image: url('../img/pricing_circles.svg');
}
.pricing .item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.pricing-page table {
  width: 100%;
  text-align: center;
}
.pricing-page .pricing-table {
  margin-top: 4rem;
  table-layout: fixed;
  font-size: 0.875rem;
  overflow-wrap: break-word;
}
.pricing-table td,
.pricing-table th {
  height: 5rem;
  padding: 1rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  background-clip: padding-box;
  word-break: break-word;
  hyphens: auto;
}
.pricing-table td:first-child,
.pricing-table th:first-child {
  border-right-width: 0;
}
.pricing-table td:last-child,
.pricing-table th:last-child {
  height: 5rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
}
.pricing-table td:first-child > div,
.pricing-table th:first-child > div {
  margin-left: auto;
}
.pricing-table td > div,
.pricing-table th > div {
  text-align: center;
  max-width: 20rem;
}
.pricing-table th,
.pricing-table th:last-child {
  padding-top: 4rem;
}
.pricing-table tbody tr:first-child td {
  border-top-width: 0;
}
.pricing-table tbody tr:last-child td {
  border-bottom-width: 0;
  padding-bottom: 4rem;
}
.pricing-page .dark-green-grad {
  border-left: 2px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      color-stop(18.24%, hsla(0, 0%, 100%, 0)),
      color-stop(81.88%, hsla(0, 0%, 100%, 0)),
      to(#fff)
    ),
    #00e76e;
  background: -o-linear-gradient(
      top,
      #fff 0,
      hsla(0, 0%, 100%, 0) 18.24%,
      hsla(0, 0%, 100%, 0) 81.88%,
      #fff 100%
    ),
    #00e76e;
  background: linear-gradient(
      180deg,
      #fff,
      hsla(0, 0%, 100%, 0) 18.24%,
      hsla(0, 0%, 100%, 0) 81.88%,
      #fff
    ),
    #00e76e;
}
.pricing-page .light-green-grad {
  background: rgb(177, 247, 211);
  background: linear-gradient(
    90deg,
    rgba(177, 247, 211, 1) 0%,
    rgba(255, 255, 255, 1) 77%
  );
}
.pricing-page .white-grad {
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(hsla(0, 0%, 100%, 0)),
      to(#00e76e)
    ),
    #fff;
  background: -o-linear-gradient(bottom, hsla(0, 0%, 100%, 0) 0, #00e76e 100%),
    #fff;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0, #00e76e), #fff;
}
.pricing-page .line-container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.pricing-page .line-container > div {
  width: 100%;
  --bg-opacity: 1;
  background-color: #00e76e;
  background-color: rgba(0, 231, 110, var(--bg-opacity));
  height: 2px;
  min-width: 2rem;
}
.pricing-example-mobile .keen-slider__slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.pricing-example-mobile .content {
  width: 60%;
}
.pricing-example-mobile .line-container {
  width: 20%;
}
.pricing-example-mobile .dot {
  width: 1.25rem;
  height: 1.25rem;
}
.pricing-example-mobile .dot:focus {
  outline: 0;
}
.pricing-example-desktop {
  display: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.pricing-example-desktop > div.content {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 12rem;
}
.pricing-example-desktop img {
  min-width: 60px;
}
.pricing-example-desktop a {
  text-decoration: none;
  color: #a7ffd1;
}
.pricing-page .question {
  font-weight: 700;
}
.pricing-page .question3 {
  max-width: 12rem;
}
.pricing-page #starting-costs-desktop {
  margin-left: auto;
  margin-right: auto;
  max-width: 56rem;
  display: none;
}
.pricing-page .starting-costs td {
  text-align: center;
  padding: 1rem;
  --bg-opacity: 1;
  background-color: #00e76e;
  background-color: rgba(0, 231, 110, var(--bg-opacity));
  background-clip: padding-box;
}
.pricing-page #starting-costs tr td:first-child {
  border-left-width: 0;
  background-color: rgba(0, 0, 0, 0);
}
.pricing-page #starting-costs tr td:last-child {
  border-right-width: 0;
  background-color: rgba(0, 0, 0, 0);
}
.pricing-page #starting-costs tr td {
  padding-top: 3rem;
}
.pricing-page #starting-costs tr:last-child td {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.pricing-page .starting-costs td div:first-child {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  font-weight: 700;
  font-size: 1.5rem;
}
.pricing-page .horizontal-white-grad {
  width: 80%;
  height: 2px;
  background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(hsla(0, 0%, 100%, 0)),
      color-stop(50%, #fff),
      to(hsla(0, 0%, 100%, 0))
    ),
    #00e76e;
  background: -o-linear-gradient(
      right,
      hsla(0, 0%, 100%, 0) 0,
      #fff 50%,
      hsla(0, 0%, 100%, 0) 100%
    ),
    #00e76e;
  background: linear-gradient(
      270deg,
      hsla(0, 0%, 100%, 0),
      #fff 50%,
      hsla(0, 0%, 100%, 0)
    ),
    #00e76e;
}
.pricing-page .footnote div:first-child:after {
  content: '*';
}
.pricing-page .footnote,
.pricing-page .footnote div:last-child {
  font-weight: 400;
  font-size: 0.875rem;
}
.pricing-page .pricing-detail {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: 50% 28%;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  background-image: url('../img/pricing_detail.svg');
  background-size: 60rem;
}
@media (min-width: 450px) {
  .pricing .item-container {
    margin-bottom: -2rem;
  }
}
@media (min-width: 640px) {
  .pricing .line-container {
    width: 16.666667%;
  }
  .pricing-example-mobile .dot {
    width: 0.5rem;
    height: 0.5rem;
  }
}
@media (min-width: 768px) {
  .pricing-example-desktop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  #starting-costs-desktop {
    display: table !important;
  }
  #starting-costs-mobile {
    display: none;
  }
  .pricing-page .footnote div:first-child:after {
    content: '';
  }
  .pricing-page .question3 {
    max-width: 20rem;
  }
  .pricing-page .pricing-detail {
    background-position: 50% 64%;
    padding-left: 2rem;
    padding-right: 2rem;
    background-size: cover;
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .pricing .line-container {
    width: 25%;
  }
  .pricing .item-container {
    margin-bottom: -4rem;
  }
}
.registration input,
.registration select,
.registration textarea {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
.registration .hint {
  color: rgba(8, 22, 43, 0.3);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 0.875rem;
}
.registration-sections .active {
  display: table-caption;
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
  font-weight: 700;
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.registration .next-button {
  display: inline;
  cursor: pointer;
  --bg-opacity: 1;
  background-color: #00e76e;
  background-color: rgba(0, 231, 110, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  border-radius: 9999px;
  margin-left: 0.5rem;
  vertical-align: bottom;
}
.registration .next-button span {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  margin-left: -5rem;
}
.registration .next-button:hover span {
  padding-left: 1.5rem;
  font-size: 1rem;
  margin-left: 0;
}
.registration .btn-login {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.registration a {
  font-weight: 700;
}
.registration h2 {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.25;
}
footer a {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
}
footer img {
  height: 100%;
}
@media (min-width: 640px) {
  .registration h2 {
    font-size: 1.5rem;
  }
  footer a {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.faq mark {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  background-color: initial;
}
.faq section > .line-container-left {
  height: 2px;
}
.faq section:not(:first-child) > .line-container-left > div,
.faq section:not(:first-child) > .line-container-left.forced-open > div,
.faq div.collapsable > section > .line-container-left.forced-open > div,
.faq
  div.collapsable
  > section:not(:first-child)
  > .line-container-left.forced-open
  > div {
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00e76e),
    to(#fff)
  );
  background-image: -o-linear-gradient(left, #00e76e, #fff);
  background-image: linear-gradient(90deg, #00e76e, #fff);
}
.faq div.collapsable > section:not(:first-child) > .line-container-left > div {
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 22, 43, 0.1)),
    to(#fff)
  );
  background-image: -o-linear-gradient(left, rgba(8, 22, 43, 0.1), #fff);
  background-image: linear-gradient(90deg, rgba(8, 22, 43, 0.1), #fff);
}
.faq input {
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  outline: 0;
}
.faq input::-webkit-input-placeholder {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  opacity: 0.5;
}
.faq input::-moz-placeholder {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  opacity: 0.5;
}
.faq input:-ms-input-placeholder {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  opacity: 0.5;
}
.faq input::-ms-input-placeholder {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  opacity: 0.5;
}
.faq input::placeholder {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
  opacity: 0.5;
}
.faq input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  padding: 10px 0px 10px 8px;
  width: 16px;
  height: 16px;
  background-image: url('../img/close.svg');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center right;
}
.faq input + svg {
  visibility: hidden;
  cursor: pointer;
  margin-left: -37px;
}
.faq input:-moz-placeholder-shown + svg {
  visibility: visible;
}
.faq input:-ms-input-placeholder + svg {
  visibility: visible;
}
.faq input:placeholder-shown + svg {
  visibility: visible;
}
.faq input::-ms-clear {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.faq h1 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}
.faq h2 {
  font-weight: 700;
  font-size: 1rem;
}
.faq h3 {
  font-size: 1rem;
  display: inline;
}
.faq .chevron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.faq .chevron:hover {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.faq .chevron:hover svg {
  fill: currentColor;
}
.faq section * {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: max-height, visibility, -webkit-transform;
  transition-property: max-height, visibility, -webkit-transform;
  -o-transition-property: transform, max-height, visibility;
  transition-property: transform, max-height, visibility;
  transition-property: transform, max-height, visibility, -webkit-transform;
}
.faq .collapsable {
  overflow: hidden;
  max-height: 9999vh;
}
.faq .collapsable.collapsed {
  visibility: hidden;
  max-height: 0;
}
.faq button.collapsed {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  -ms-transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  --transform-rotate: 180deg;
  margin-top: 0.5rem;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  font-size: 0.875rem;
  padding: 1rem 3rem;
  z-index: 40;
}
.cookie-banner a {
  text-decoration: none;
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}
.cookie-banner a:hover {
  text-decoration: underline;
}

.analytics-container .date-picker-alt {
  width: auto;
}
.analytics-container .date-picker-alt:focus {
  outline: 0;
}
@media (min-width: 640px) {
  .faq h1 {
    font-size: 2.25rem;
    padding-bottom: 0;
  }
  .faq h2 {
    font-size: 1.25rem;
  }
  .faq h3 {
    font-size: 1.125rem;
  }
}
@media (min-width: 1024px) {
  .registration h2 {
    font-size: 1.875rem;
  }
}

.new-password-hint {
  color: rgba(8, 22, 43, 0.3);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * (1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse));
}
.divide-secondary > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--divide-opacity));
}
.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
.bg-secondary {
  --bg-opacity: 1;
  background-color: #00e76e;
  background-color: rgba(0, 231, 110, var(--bg-opacity));
}
.bg-navy {
  --bg-opacity: 1;
  background-color: #08162b;
  background-color: rgba(8, 22, 43, var(--bg-opacity));
}
.bg-center {
  background-position: 50%;
}
.bg-left {
  background-position: 0;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-cover {
  background-size: cover;
}
.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}
.border-pink-500 {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity));
}
.border-secondary {
  --border-opacity: 1;
  border-color: #00e76e;
  border-color: rgba(0, 231, 110, var(--border-opacity));
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-none {
  border-style: none;
}
.border {
  border-width: 1px;
}
.border-l-2 {
  border-left-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.h-10 {
  height: 2.5rem;
}
.h-64 {
  height: 16rem;
}
.h-96 {
  height: 24rem;
}
.h-full {
  height: 100%;
}
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.leading-none {
  line-height: 1;
}
.m-4 {
  margin: 1rem;
}
.m-8 {
  margin: 2rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-32 {
  margin-top: 8rem;
}
.mb-32 {
  margin-bottom: 8rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mb-64 {
  margin-bottom: 16rem;
}
.ml-auto {
  margin-left: auto;
}
.-mt-3 {
  margin-top: -0.75rem;
}
.-mt-12 {
  margin-top: -3rem;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-1\/2 {
  max-width: 50%;
}
.object-scale-down {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}
.object-bottom {
  -o-object-position: bottom;
  object-position: bottom;
}
.object-left {
  -o-object-position: left;
  object-position: left;
}
.opacity-0 {
  opacity: 0;
}
.opacity-50 {
  opacity: 0.5;
}
.overflow-hidden {
  overflow: hidden;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-8 {
  padding: 2rem;
}
.p-10 {
  padding: 2.5rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pb-64 {
  padding-bottom: 16rem;
}
.pointer-events-auto {
  pointer-events: auto;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.resize {
  resize: both;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-navy {
  --text-opacity: 1;
  color: #08162b;
  color: rgba(8, 22, 43, var(--text-opacity));
}
.no-underline {
  text-decoration: none;
}
.invisible {
  visibility: hidden;
}
.whitespace-no-wrap {
  white-space: nowrap;
}
.w-0 {
  width: 0;
}
.w-40 {
  width: 10rem;
}
.w-48 {
  width: 12rem;
}
.w-64 {
  width: 16rem;
}
.w-auto {
  width: auto;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-3\/12 {
  width: 25%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-full {
  width: 100%;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.-z-10 {
  z-index: -10;
}
.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  -ms-transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}
.transition-none {
  -webkit-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}
.ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.duration-300 {
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.delay-500 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.delay-1000 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
/* @-webkit-keyframes spin {
  :global(to) {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes ping {
  :global(to) {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes ping {
  75%,
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  :global(50%) {
    opacity: 0.5;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@-webkit-keyframes bounce {
  :global(0%),
  :global(to) {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  :global(50%) {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes bounce {
  0%,
  to {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    -webkit-transform: none;
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
} */
@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:order-last {
    -webkit-box-ordinal-group: 10000;
    -ms-flex-order: 9999;
    order: 9999;
  }
  .sm\:text-base {
    font-size: 1rem;
  }
  .sm\:text-xl {
    font-size: 1.25rem;
  }
  .sm\:text-3xl {
    font-size: 1.875rem;
  }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:mr-0 {
    margin-right: 0;
  }
  .sm\:mt-4 {
    margin-top: 1rem;
  }
  .sm\:mb-8 {
    margin-bottom: 2rem;
  }
  .sm\:mb-16 {
    margin-bottom: 4rem;
  }
  .sm\:mt-24 {
    margin-top: 6rem;
  }
  .sm\:mt-32 {
    margin-top: 8rem;
  }
  .sm\:mt-48 {
    margin-top: 12rem;
  }
  .sm\:max-w-sm {
    max-width: 24rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:pr-4 {
    padding-right: 1rem;
  }
  .sm\:pl-4 {
    padding-left: 1rem;
  }
  .sm\:pl-8 {
    padding-left: 2rem;
  }
  .sm\:pb-32 {
    padding-bottom: 8rem;
  }
  .sm\:text-center {
    text-align: center;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-1\/2 {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
  .md\:hidden {
    display: none;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
  }
  .md\:m-0 {
    margin: 0;
  }
  .md\:mt-32 {
    margin-top: 8rem;
  }
  .md\:mb-32 {
    margin-bottom: 8rem;
  }
  .md\:mt-48 {
    margin-top: 12rem;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:max-w-lg {
    max-width: 32rem;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .md\:pb-24 {
    padding-bottom: 6rem;
  }
  .md\:pr-48 {
    padding-right: 12rem;
  }
  .md\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:text-right {
    text-align: right;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:w-1\/3 {
    width: 33.333333%;
  }
  .md\:w-2\/5 {
    width: 40%;
  }
  .md\:w-3\/5 {
    width: 60%;
  }
}
@media (min-width: 1024px) {
  .lg\:text-4xl {
    font-size: 2.25rem;
  }
  .lg\:text-5xl {
    font-size: 3rem;
  }
  .lg\:mt-16 {
    margin-top: 4rem;
  }
  .lg\:ml-24 {
    margin-left: 6rem;
  }
  .lg\:-ml-16 {
    margin-left: -4rem;
  }
  .lg\:max-w-lg {
    max-width: 32rem;
  }
  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (min-width: 1280px) {
  .xl\:text-4xl {
    font-size: 2.25rem;
  }
  .xl\:mt-32 {
    margin-top: 8rem;
  }
  .xl\:mt-64 {
    margin-top: 16rem;
  }
}

.logged-in {
  --bg-opacity: 1;
  background-color: #08162b;
  background-color: rgba(8, 22, 43, var(--bg-opacity));
}
.logged-in .nav-text {
  --text-opacity: 1;
  color: #00e76e;
  color: rgba(0, 231, 110, var(--text-opacity));
}

.swipe-indicator {
  position: relative;
  bottom: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  pointer-events: none;
}
.dot {
  height: 10px;
  width: 10px;
  background: rgba(0, 231, 110, 0.2);
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.5rem;
  cursor: pointer;
  pointer-events: fill;
}
.swipe-indicator .is-active {
  background-color: #00e76e;
}
.swipe-indicator-top .dot {
  background: hsla(0, 0%, 100%, 0.4);
}
.swipe-indicator-top .is-active {
  background-color: #fff;
}

@tailwind utilities;
