.sidebar a {
  @apply py-2 no-underline;
}

.clientsLink {
  @apply text-lg;
}

.langSelectLink {
  @apply text-lg font-semibold;
}

.langSelectActive{
  @apply border-b border-secondary;
}
